import React from 'react';
import { Link } from 'gatsby';
import { FaChevronLeft } from 'react-icons/fa';
import '../components/style.scss';

const danceDancePose = () => (
  <section className="section">
    <div className="container">
      <div className="columns">
        <Link to="/#projects">
          <button
            type="button"
            className="button is-outlined is-primary is-medium"
          >
            <FaChevronLeft />
          </button>
        </Link>
        <div className="column is-half is-size-5">
          <h1 className="title is-size-1 is-centered">Dance Dance Pose</h1>
          Dance Dance Pose is a singing and dancing game that puts your skills
          to the test by using motion capture and pitch detection. Inspired by
          popular games such as Dance Dance Revolution and Rockband, users must
          sing on key and dance to their favorite songs in order to earn points.
          Utilizing web audio detection and Posenet by TensorFlow, DDP gives
          users a new interactive experience with fan favorites.
          <br />
          <br />
          <a
            className="text is-size-5"
            href="https://dance-dance-pose.herokuapp.com/"
          >
            https://dance-dance-pose.herokuapp.com/
          </a>
          <br />
          <a
            className="text is-size-5"
            href="https://github.com/dancing-karaoke/Dance-Dance-Pose"
          >
            Source Code
          </a>
          <br />
          <br />
          Shout out to the team: <br />
          <a href="https://github.com/joecosta117">Joe Costa</a>
          <br />
          <a href="https://github.com/paolaneira">Paola Neira</a>
          <br />
          <a href="https://github.com/jimmyhuang">Jimmy Huang</a>
          <br />
          <br />
          <iframe
            title="dance-dance-pose"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/PJRBaXsnw8Y"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  </section>
);

export default danceDancePose;
